import React, { Component } from "react";
import { Badge, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import TextField from "@mui/material/TextField";

//API handling components
import { API_URL } from "../Global";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
// require("pdfmake");

const axios = require("axios");

export class ExpensesManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: "",
            amount: "",
            name: "",
            expenseData: {},
        };
    }
    getExpenseData() {
        const query = `SELECT * from personal_expenses order by id desc;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log(res.data);
                this.setState(
                    { expenseData: res.data },
                    this.initializeDataTable
                );
                // this.initializeDataTable();
            })
            .catch((err) => {
                console.log(err);
                toast("failed to fetch");
            });
    }
    componentDidMount() {
        this.getExpenseData();
    }

    initializeDataTable() {
        $(function () {
            $("#expenses_table")
                .DataTable({
                    destroy: true,
                    dom:
                        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                        "<'row'<'col-sm-12' tr>>" +
                        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                    responsive: true,
                    lengthChange: false,
                    autoWidth: false,
                    buttons: ["csv", "print"],
                    order: [[0, "desc"]],
                })
                .buttons();
        });
    }

    refresh() {
        window.location.reload(false);
    }

    render() {
        return (
            <div className="container-fluid">
                <div
                    // component={''}
                    style={{ width: "100%" }}
                    className="mt-2"
                >
                    <table
                        id="expenses_table"
                        className="display table table-striped"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th align="center">Expense Id</th>
                                <th>Name</th>
                                <th>debit</th>
                                <th>debit reason</th>
                                <th>credit</th>
                                <th>credit reason</th>
                                <th>attachment</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.expenseData.length > 0
                                ? this.state.expenseData.map((expense) => {
                                      return (
                                          <tr key={expense.id}>
                                              <td align="center">
                                                  {expense.id}
                                              </td>
                                              <td> {expense.name}</td>
                                              <td>{expense.debit}</td>
                                              <td
                                                  style={{
                                                      textTransform:
                                                          "capitalize",
                                                  }}
                                              >
                                                  {expense.debitReason}
                                              </td>
                                              <td>{expense.credit}</td>
                                              <td
                                                  style={{
                                                      textTransform:
                                                          "capitalize",
                                                  }}
                                              >
                                                  {expense.creditReason}
                                              </td>

                                              <td align="center">
                                                  {expense.docPath !=
                                                      "File upload failed" && (
                                                      <a
                                                          rel="noreferrel"
                                                          href={`${expense.attachment}`}
                                                          download
                                                          target="_blank"
                                                          className="disabled"
                                                      >
                                                          view
                                                      </a>
                                                  )}
                                              </td>
                                              <td>
                                                  {moment(expense.date).format(
                                                      "DD/MM/YYYY"
                                                  )}
                                              </td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={3000}
                />
            </div>
        );
    }
}

export default ExpensesManager;
