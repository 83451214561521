import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

import { API_URL } from "./Global";

const axios = require("axios");

const AddNewRecord = () => {
    const [activeDocPath, setActiveDocPath] = useState();
    const [selectedFile, setSelectedFile] = useState(null);

    const [formData, setFormData] = useState("");

    const submitFile = () => {
        if (selectedFile == null) {
            setActiveDocPath("no file");
            handleSubmit("no file");
        } else {
            const data = new FormData();
            data.append("fileToUpload", selectedFile);
            let url = "https://imprint24.5techg.com/fileUpload/index.php";

            axios.post(url, data).then((res) => {
                // then print response status
                console.warn(res);
                setActiveDocPath(res.data);
                handleSubmit(res.data);
            });
        }
    };
    const handleSubmit = (activeDocPath) => {
        let date = formData.date
            ? moment(formData.date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD");
        let credit = formData.credit ? formData.credit : 0;
        let debit = formData.debit ? formData.debit : 0;
        let creditReason =
            formData.creditReason != null ? formData.creditReason : "N/A";
        let debitReason =
            formData.debitReason != null ? formData.debitReason : "N/A";
        const query = `INSERT INTO personal_expenses(name, debit, debitReason, credit, creditReason, attachment, date)
         VALUES (
          '${formData.name}'
          ,${debit}
          ,'${debitReason}'
          ,${credit}
          ,'${creditReason}'
          ,'${activeDocPath}'
          ,'${date}'
        )`;
        console.log(query);

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast("expense saved successfully");
                console.log("expense saved successfully");
            })
            .catch((err) => {
                console.log(err);
                toast("failed to save");
            });
    };
    const handleInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    return (
        <div className="container mx-auto flex justify-center items-center">
            <form class="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4 md:w-1/3">
                <div class="mb-4">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="username"
                    >
                        Username
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        placeholder="Username"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                name: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="mb-6">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="password"
                    >
                        Credit
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="credit"
                        type="number"
                        value={formData.credit != null ? formData.credit : 0}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                credit: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="mb-6">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="password"
                    >
                        Credit Reason
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="credit"
                        type="text"
                        value={
                            formData.creditReason != null
                                ? formData.creditReason
                                : "N/A"
                        }
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                creditReason: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="mb-6">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="password"
                    >
                        Debit
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="debit"
                        type="number"
                        value={formData.debit != null ? formData.debit : 0}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                debit: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="mb-6">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="password"
                    >
                        Debit Reason
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="credit"
                        type="text"
                        value={
                            formData.debitReason != null
                                ? formData.debitReason
                                : "N/A"
                        }
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                debitReason: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="mb-6">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="password"
                    >
                        Date
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="date"
                        type="date"
                        value={
                            formData.date != null
                                ? formData.date
                                : moment(new Date()).format("YYYY-MM-DD")
                        }
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                date: e.target.value,
                            })
                        }
                    />
                </div>
                <div class="flex justify-center mt-8 mb-4">
                    <div class="max-w-2xl rounded-lg shadow-xl bg-gray-50">
                        <div class="m-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2">
                                Attachment
                            </label>

                            <input
                                type="file"
                                name="fileToUpload"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div class="flex mx-auto justify-evenly pt-6">
                    <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={(e) => {
                            submitFile();
                        }}
                    >
                        SAVE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddNewRecord;
