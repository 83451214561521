import React, { useState, useEffect } from "react";

import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { API_URL } from "./Global";
import logo from "../src/images/logo.png";
import {
    Col,
    Row,
    ButtonGroup,
    ToggleButton,
    Modal,
    Navbar,
    Container,
    Nav,
    NavDropdown,
} from "react-bootstrap";
import { HashRouter, Routes, Route, Link } from "react-router-dom";

import Login from "./auth/Login";
import ExpensesManager from "./ExpensesManger/ExpensesManager";
import AddNewRecord from "./AddNewRecord";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";

const axios = require("axios");

export const AuthContext = React.createContext();

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("isLoggedIn") == "true" ? true : false
    );
    const [userType, setUserType] = useState(
        localStorage.getItem("userType") != "0"
            ? Number(localStorage.getItem("userType"))
            : false
    );

    const [activeTab, setActiveTab] = useState(0);

    const refresh = () => {
        window.location.reload(false);
    };

    return (
        <div>
            <AuthContext.Provider
                value={[
                    [isLoggedIn, setIsLoggedIn],
                    [userType, setUserType],
                ]}
            >
                <HashRouter>
                    {isLoggedIn && (
                        <div className="container">
                            <Navbar
                                collapseOnSelect
                                expand="lg"
                                className="mt-3 text-dark"
                                style={{
                                    backgroundColor: "#badcf9",
                                    height: "50px",
                                }}
                            >
                                <Container
                                    style={{
                                        backgroundColor: "#badcf9",
                                        zIndex: "100",
                                    }}
                                >
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="mx-auto ">
                                            <Nav.Link
                                                className={
                                                    activeTab === 1
                                                        ? "bg-warning border border-warning rounded mr-5 mr-5"
                                                        : "mr-5"
                                                }
                                                onClick={() => setActiveTab(1)}
                                            >
                                                <Link
                                                    to="/addNewRecord"
                                                    className="mt-3 h6"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    Add new entry
                                                </Link>
                                            </Nav.Link>
                                            <Nav.Link
                                                className={
                                                    activeTab === 2
                                                        ? "bg-warning border border-warning rounded mr-5 mr-5"
                                                        : "mr-5"
                                                }
                                                onClick={() => setActiveTab(2)}
                                            >
                                                <Link
                                                    to="/expensesManager"
                                                    className="mt-3 h6"
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    History
                                                </Link>
                                            </Nav.Link>
                                            <Nav.Link>
                                                <Button className="ml-5">
                                                    <ExitToAppIcon
                                                        onClick={(e) => {
                                                            localStorage.clear();
                                                            refresh();
                                                        }}
                                                    />
                                                </Button>
                                            </Nav.Link>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>

                            <Routes>
                                <Route
                                    path="/addNewRecord"
                                    element={<AddNewRecord />}
                                />
                                <Route
                                    path="/ExpensesManager"
                                    element={<ExpensesManager />}
                                />
                            </Routes>
                            <ToastContainer />
                        </div>
                    )}
                </HashRouter>
                {!isLoggedIn && <Login />}
            </AuthContext.Provider>
        </div>
    );
}
